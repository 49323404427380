<template>
  <account-code-select
    v-model="sCode"
    :disabled="disabled"
    payment-method-code="NINGUNO"
  >
    <template #append>
      <v-btn
        :disabled="btnDisabled"
        :loading="loading"
        color="primary"
        depressed
        icon
        outlined
        x-small
        @click.stop.prevent="onSave"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
  </account-code-select>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import { Currency, type CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { ConfigModule } from "@/store/config";
import { find } from "lodash";
import useCompanyHelper from "@/composables/company";

@Component({
  components: { AccountCodeSelect },
})
export default class CompanyCurrencyAccountCode extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: CurrencyData;
  @Prop(Number) readonly companyId!: number;
  @Prop(Boolean) readonly disabled!: number;

  loading = false;
  obCurrency: Currency | null | undefined = null;

  get company() {
    return AppModule.company;
  }

  get iCompanyId(): number {
    return this.companyId || this.company.id;
  }

  get btnDisabled(): boolean {
    return !this.obCurrency?.isDirty("code_account");
  }

  get sCode() {
    return this.obCurrency?.get("code_account", null);
  }

  set sCode(sValue: string) {
    if (!this.obCurrency) {
      return;
    }

    this.obCurrency.set("code_account", sValue);
  }

  async load() {
    if (this.companyId === this.company.id) {
      const arCompanyCurrencies = ConfigModule.companyCurrencies;
      const obModel = find(arCompanyCurrencies, { id: this.value.id });

      if (obModel) {
        this.obCurrency = obModel;
        return;
      }
    }

    this.loading = true;
    const obCompanyHelper = useCompanyHelper(this.companyId);
    const arCurrencies = await obCompanyHelper.loadCurrencies();
    const obCurrencyData = find(arCurrencies, { id: this.value.id });

    if (obCurrencyData) {
      this.obCurrency = new Currency(obCurrencyData as CurrencyData);
    }

    this.loading = false;
  }

  async onSave() {
    if (!this.obCurrency?.isDirty("code_account")) {
      return;
    }

    this.loading = true;
    this.obCurrency.sync();
    await this.obCurrency.store();

    if (this.companyId === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencies(true);
    }

    this.loading = false;
    await this.load();
  }

  mounted() {
    this.load();
  }
}
</script>
